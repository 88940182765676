import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
// import store from '@/store'
// import axios from 'axios'
// import router from '@/router/'
// import store from '@/store'

export default {
  name: 'AdministracionClientesView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      show: false,
      file: null,
      arrayBuffer: null,
      filelist: null,
      fileEntregaDiaria: null,
      fileEntregaDiariaV2: null,
      fileRouteView: null,
      fileMaestroClientes: null,
      fileClientesFoco: null,
      filePedidosItems: null,
      filePlanificacion: null,
      fileSpmk: null,
      spmk: [],
      planificacion: [],
      pedidosItems: [],
      clientesFoco: [],
      clientesExcel: [],
      // entregaDiaria: [],
      stopView: [],
      stopV2View: [],
      maestroClientes: []
    }
  },
  watch: {
    // updateClientes: function (val) {
    // }
  },
  mounted () {
    // this.getAllClientes().then((res) => {
    // })
  },
  methods: {
    ...mapActions('Clientes', [
      'getAllClientes', 'insertClientes', 'deleteCliente', 'updateClienteFoco'
    ]),
    ...mapActions('EntregaDiaria', [
      'getEntregaDiaria', 'actualizaEntregaDiariaMotivoNoEntrega', 'setEntregaDiaria', 'updateEntregaDiariaAsync', 'setEntregaDiariaAsync', 'updatePlanificacionAsync', 'updatePedidosIntemsAsync'
    ]),
    ...mapActions('Reportes', [
      'setPlanificaciones'
    ]),
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        autoHideDelay: 5000
      })
    },
    addfile (event, tipo) {
      this.show = true
      // console.log(event)
      console.log(tipo)
      if (tipo === 'entregaDiariaV2') {
        this.fileEntregaDiariaV2 = event.target.files[0]
      } else if (tipo === 'maestroClientes') {
        this.fileMaestroClientes = event.target.files[0]
      } else if (tipo === 'routeView') {
        this.fileRouteView = event.target.files[0]
      } else if (tipo === 'clientesFoco') {
        this.fileClientesFoco = event.target.files[0]
      } else if (tipo === 'pedidosItems') {
        this.filePedidosItems = event.target.files[0]
      } else if (tipo === 'planificacion') {
        this.filePlanificacion = event.target.files[0]
      } else if (tipo === 'spmk') {
        this.fileSpmk = event.target.files[0]
      } else {
        this.file = event.target.files[0]
      }
      console.log('selecciona tipo')
      const fileReader = new FileReader()
      try {
        console.log('try read')
        fileReader.readAsArrayBuffer(event.target.files[0])
        console.log('end read')
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        console.log('comienza onload')
        this.arrayBuffer = fileReader.result
        console.log(1)
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        console.log(2)
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        console.log(3)
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        console.log(4)
        const worksheet = workbook.Sheets[firstSheetName]
        console.log(5)
        // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
        // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
        const fileUpload = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        this.filelist = []
        console.log(fileUpload)
        if (tipo === 'entregaDiaria') {
          this.stopView = fileUpload
        } else if (tipo === 'maestroClientes') {
          this.maestroClientes = fileUpload
        } else if (tipo === 'routeView') {
          this.fileRouteView = fileUpload
        } else if (tipo === 'clientesFoco') {
          this.clientesFoco = fileUpload
        } else if (tipo === 'pedidosItems') {
          this.pedidosItems = fileUpload
        } else if (tipo === 'planificacion') {
          this.planificacion = fileUpload
        } else if (tipo === 'spmk') {
          this.spmk = fileUpload
        } else if (tipo === 'entregaDiariaV2') {
          this.stopV2View = fileUpload
        }
        this.show = false
      }
    },
    cargarExcel () {
      this.insertClientes(this.clientesExcel).then((res) => {
        console.log('cargarExcel')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
        this.clientesExcel = []
        this.$emit('updateClientes', this.clientes)
      })
    },
    cargarClientesFoco () {
      // console.log(this.clientesFoco)
      this.updateClienteFoco(this.clientesFoco).then((res) => {
        // console.log('cargarClientesFoco')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
        this.clientesFoco = []
        // this.fileClientesFoco = null
        // this.arrayBuffer = null
      })
    },
    cargarStopView () {
      console.log('stopView')
      console.log(this.stopView)
      let date = new Date(1900, 0, 1, 0, 0, 0)
      let fechaFormat = ''
      let fecha = 0
      let entregaFilter = {}
      const transportes = []
      for (const stopView in this.stopView) {
        entregaFilter = {
          nro_transporte: 0,
          vuelta: 0,
          fecha_ruta: '',
          cd: '',
          // nro_camion: 0,
          nro_cliente: 0
        }
        fecha = 0
        fechaFormat = ''
        date = new Date(1900, 0, 1, 0, 0, 0)
        fecha = this.stopView[stopView]['Fecha de ruta']
        // fecha = ''
        // console.log(fecha)
        const milisegundos = fecha * 24 * 60 * 60 * 1000
        // console.log(milisegundos)
        const milisegundosActual = date.getMilliseconds()
        // console.log(milisegundosActual)
        date.setMilliseconds(milisegundos + milisegundosActual - (24 * 60 * 60 * 1000))
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        // console.log(date)
        fechaFormat = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')} ${
                '00'}:${
                  '00'}:${
                    '00'}`
        // console.log(fechaFormat)
        const transporte = this.stopView[stopView]['CÃ³digo de ruta']
        const botellas = this.stopView[stopView]['Original {size3Alias}']
        const cajas = this.stopView[stopView]['Original {size1Alias}']
        const pac = this.stopView[stopView]['Original {size2Alias}']
        if (typeof transporte === 'string') {
          const index = transporte.indexOf('_')
          entregaFilter.nro_transporte = parseInt(transporte.substring(0, index))
          entregaFilter.vuelta = parseInt(transporte.substring(index + 1, transporte.length))
          entregaFilter.cd = this.stopView[stopView].Origen
          entregaFilter.fecha_ruta = fechaFormat
          entregaFilter.nro_cliente = this.stopView[stopView]['CÃ³digo de la UbicaciÃ³n']
          entregaFilter.nombre_cliente = this.stopV2View[stopView]['DescripciÃ³n de la ubicaciÃ³n']
          entregaFilter.direccion = this.stopV2View[stopView]['DirecciÃ³n de la UbicaciÃ³n']
          entregaFilter.botellas = (botellas !== undefined && botellas !== null ? parseInt(botellas) : null)
          entregaFilter.cajas = (cajas !== undefined && cajas !== null ? parseInt(cajas) : null)
          entregaFilter.pac = (pac !== undefined && pac !== null ? parseInt(pac) : null)
          if (entregaFilter.nro_camion !== 0) transportes.push(entregaFilter)
        } else {
          entregaFilter.nro_transporte = transporte
          entregaFilter.vuelta = transporte
        }
      }
      console.log(transportes)
      this.setEntregaDiariaAsync(transportes).then((res) => {
        console.log('setEntregaDiaria')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    },
    cargarStopV2View () {
      console.log('stopV2View')
      console.log(this.stopV2View)
      let date = new Date(1900, 0, 1, 0, 0, 0)
      let fechaFormat = ''
      let fecha = 0
      let entregaFilter = {}
      const transportes = []
      for (const stopView in this.stopV2View) {
        entregaFilter = {
          nro_transporte: 0,
          vuelta: 0,
          fecha_ruta: '',
          cd: '',
          // nro_camion: 0,
          nro_cliente: 0
        }
        fecha = 0
        fechaFormat = ''
        date = new Date(1900, 0, 1, 0, 0, 0)
        fecha = this.stopV2View[stopView]['Fecha de ruta']
        // fecha = ''
        // console.log(fecha)
        const milisegundos = fecha * 24 * 60 * 60 * 1000
        // console.log(milisegundos)
        const milisegundosActual = date.getMilliseconds()
        // console.log(milisegundosActual)
        date.setMilliseconds(milisegundos + milisegundosActual - (24 * 60 * 60 * 1000))
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        // console.log(date)
        fechaFormat = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')} ${
                '00'}:${
                  '00'}:${
                    '00'}`
        // console.log(fechaFormat)
        const transporte = this.stopV2View[stopView]['CÃ³digo de ruta']
        const botellas = this.stopV2View[stopView]['Planificado {size3Alias}']
        const cajas = this.stopV2View[stopView]['Planificado {size1Alias}']
        const pac = this.stopV2View[stopView]['Planificado {size2Alias}']
        if (typeof transporte === 'string') {
          const index = transporte.indexOf('_')
          entregaFilter.nro_transporte = parseInt(transporte.substring(0, index))
          entregaFilter.vuelta = parseInt(transporte.substring(index + 1, transporte.length))
          entregaFilter.cd = this.stopV2View[stopView].Origen
          entregaFilter.fecha_ruta = fechaFormat
          entregaFilter.nro_cliente = this.stopV2View[stopView]['CÃ³digo de la UbicaciÃ³n']
          entregaFilter.nombre_cliente = this.stopV2View[stopView]['DescripciÃ³n de la ubicaciÃ³n']
          entregaFilter.direccion = this.stopV2View[stopView]['DirecciÃ³n de la UbicaciÃ³n']
          entregaFilter.botellas = (botellas !== undefined && botellas !== null ? parseInt(botellas) : null)
          entregaFilter.cajas = (cajas !== undefined && cajas !== null ? parseInt(cajas) : null)
          entregaFilter.pac = (pac !== undefined && pac !== null ? parseInt(pac) : null)
          if (entregaFilter.nro_camion !== 0) transportes.push(entregaFilter)
        } else {
          entregaFilter.nro_transporte = transporte
          entregaFilter.vuelta = transporte
        }
      }
      console.log(transportes)
      this.setEntregaDiariaAsync(transportes).then((res) => {
        console.log('setEntregaDiaria')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    },
    cargarRouteView () {
      console.log('cargarRouteView')
      let date = new Date(1900, 0, 1, 0, 0, 0)
      let fechaFormat = ''
      let fecha = 0
      let entregaFilter = {}
      const transportes = []
      for (const routeView in this.fileRouteView) {
        entregaFilter = {
          nro_transporte: 0,
          vuelta: 0,
          fecha_ruta: '',
          cd: '',
          nro_camion: 0
        }
        fecha = 0
        fechaFormat = ''
        date = new Date(1900, 0, 1, 0, 0, 0)
        fecha = this.fileRouteView[routeView].Fecha
        // fecha = ''
        // console.log(fecha)
        const milisegundos = fecha * 24 * 60 * 60 * 1000
        // console.log(milisegundos)
        const milisegundosActual = date.getMilliseconds()
        // console.log(milisegundosActual)
        date.setMilliseconds(milisegundos + milisegundosActual - (24 * 60 * 60 * 1000))
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        // console.log(date)
        fechaFormat = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')} ${
                '00'}:${
                  '00'}:${
                    '00'}`
        // console.log(fechaFormat)
        const transporte = this.fileRouteView[routeView]['CÃ³digo']
        // const nombreConductor = this.fileRouteView[routeView]['Conductor principal']
        // console.log(transporte)
        if (typeof transporte === 'string') {
          const index = transporte.indexOf('_')
          entregaFilter.nro_transporte = parseInt(transporte.substring(0, index))
          entregaFilter.vuelta = parseInt(transporte.substring(index + 1, transporte.length))
          entregaFilter.cd = this.fileRouteView[routeView].Origen
          entregaFilter.fecha_ruta = fechaFormat
          // if (nombreConductor !== '' && nombreConductor !== null && nombreConductor !== undefined) entregaFilter.nombre_conductor = this.fileRouteView[routeView]['Conductor principal']
          if (typeof this.fileRouteView[routeView]['Equipo Principal'] === 'number') entregaFilter.nro_camion = this.fileRouteView[routeView]['Equipo Principal']
          if (entregaFilter.nro_camion !== 0) transportes.push(entregaFilter)
        } else {
          entregaFilter.nro_transporte = transporte
          entregaFilter.vuelta = transporte
        }
      }
      // console.log(transportes)
      this.updateEntregaDiariaAsync(transportes).then((res) => {
        console.log('updateRouteView')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
        this.fileRouteView = []
        // this.$emit('updateClientes', this.clientes)
      })
    },
    cargarMaestroClientes () {
      console.log('cargarMaestroClientes')
      console.log(this.maestroClientes)
      const clientes = []
      for (const cliente in this.maestroClientes) {
        let nombreFantasia = ''
        if (this.maestroClientes[cliente]['Nombre de fantasía'] !== undefined && this.maestroClientes[cliente]['Nombre de fantasía'].toString().length > 30) {
          nombreFantasia = this.maestroClientes[cliente]['Nombre de fantasía'].toString().substring(0, 30)
        } else {
          nombreFantasia = this.maestroClientes[cliente]['Nombre de fantasía']
        }
        const telefono = []
        if (this.maestroClientes[cliente]['Teléfono 1'] !== undefined && this.maestroClientes[cliente]['Teléfono 1'] !== '') {
          telefono.push(this.maestroClientes[cliente]['Teléfono 1'])
        }
        if (this.maestroClientes[cliente]['Teléfono 2'] !== undefined && this.maestroClientes[cliente]['Teléfono 1'] !== '') {
          telefono.push(this.maestroClientes[cliente]['Teléfono 2'])
        }
        const datos = {
          nro_cliente_sap: this.maestroClientes[cliente].Cliente,
          canal_venta: this.maestroClientes[cliente]['Zona de Ventas'],
          territorio_venta: this.maestroClientes[cliente]['Ruta de venta'],
          telefono: telefono,
          razon_social: this.maestroClientes[cliente]['Nombre 1'],
          nombre_fantasia: nombreFantasia,
          direccion_despacho: this.maestroClientes[cliente].Calle + ', ' + this.maestroClientes[cliente].Población,
          contacto: this.maestroClientes[cliente]['Correo electrónico']
        }
        clientes.push(datos)
      }
      console.log(clientes)
      this.insertClientes(clientes).then((res) => {
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    },
    pedidosItemsView () {
      console.log('pedidosItemsView')
      const nuevoObjeto = {}
      let date = new Date(1900, 0, 1, 0, 0, 0)
      let fechaFormat = ''
      let fecha = 0
      this.pedidosItems.forEach(x => {
        if (!String(x['routeView.route.key']).includes('_')) return
        fecha = 0
        fechaFormat = ''
        date = new Date(1900, 0, 1, 0, 0, 0)
        fecha = x['routeView.route.date']
        const milisegundos = fecha * 24 * 60 * 60 * 1000
        const milisegundosActual = date.getMilliseconds()
        date.setMilliseconds(milisegundos + milisegundosActual - (24 * 60 * 60 * 1000))
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        fechaFormat = `${
          date.getFullYear().toString().padStart(4, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${
              date.getDate().toString().padStart(2, '0')} ${
                '00'}:${
                  '00'}:${
                    '00'}`
        const index = x['routeView.route.key'].indexOf('_')
        const nroTranporte = parseInt(x['routeView.route.key'].substring(0, index))
        const vuelta = parseInt(x['routeView.route.key'].substring(index + 1))
        const nroCliente = x['CÃ³digo de la UbicaciÃ³n']
        const nroDocumento = x['stop.orders.number']
        const param = nroTranporte + ' ' + nroCliente
        if (!Object.prototype.hasOwnProperty.call(nuevoObjeto, param)) {
          nuevoObjeto[param] = {
            documentos: [],
            nro_transporte: 0,
            nro_cliente: 0,
            vuelta: 0,
            fecha_ruta: ''
          }
        }
        nuevoObjeto[param].nro_transporte = nroTranporte
        nuevoObjeto[param].nro_cliente = nroCliente
        nuevoObjeto[param].vuelta = vuelta
        nuevoObjeto[param].fecha_ruta = fechaFormat
        nuevoObjeto[param].documentos.push({
          nro_transporte: nroTranporte,
          nro_cliente: nroCliente,
          nro_documento: nroDocumento,
          vuelta: vuelta,
          fecha: fechaFormat,
          id: x.id
        })
      })
      const result = []
      for (const data in nuevoObjeto) {
        result.push(nuevoObjeto[data])
      }
      console.log(result)
      this.updatePedidosIntemsAsync(result).then((res) => {
        console.log('setEntregaDiaria')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    },
    cargaPlanificacion () {
      console.log(this.planificacion)
      const planificaciones = []
      this.planificacion.forEach(p => {
        let pedido = ''
        if (typeof p.pedido === 'string') {
          pedido = p.pedido
        } else {
          pedido = String(p.pedido)
        }
        const partes = p['Fecha Entrega'].split('.')
        const dia = partes[0]
        const mes = partes[1]
        const anio = partes[2]
        // Luego, creamos un objeto Date con la fecha en formato ISO
        const fechaISO = `${anio}-${mes}-${dia}T00:00:00Z`
        const fecha = new Date(fechaISO)
        // Finalmente, creamos una cadena con la fecha en el formato deseado
        const fechaFormateada = fecha.toISOString().replace('T', ' ').replace(/\.\d{3}Z$/, '')
        const planificacion = {
          uc: p.UC,
          cliente: p.Cliente,
          transporte: p.Transporte,
          // fecha_entrega: p['Fecha Entrega'],
          fecha_entrega: fechaFormateada,
          pedido: pedido,
          nro_vehiculo: p['N° Vehiculo']
        }
        planificaciones.push(planificacion)
      })
      console.log(planificaciones)
      // updatePlanificacionAsync
      this.updatePlanificacionAsync(planificaciones).then((res) => {
        console.log('updatePlanificacionAsync')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
      })
    },
    cargaSpmk () {
      // console.log(this.spmk)
      const spmk = []
      this.spmk.forEach(p => {
        // const fechaOriginal = String(p['Fecha Entrega'])
        // Primero, dividimos la cadena en partes para obtener el día, mes y año.
        if (p.Transporte === '' || p.Transporte === null || p.Transporte === undefined) return
        const partes = p['Fecha Entrega'].split('.')
        const dia = partes[0]
        const mes = partes[1]
        const anio = partes[2]
        // Luego, creamos un objeto Date con la fecha en formato ISO
        const fechaISO = `${anio}-${mes}-${dia}T00:00:00Z`
        const fecha = new Date(fechaISO)
        // Finalmente, creamos una cadena con la fecha en el formato deseado
        const fechaFormateada = fecha.toISOString().replace('T', ' ').replace(/\.\d{3}Z$/, '')
        let nroDocumento = null
        if (typeof p['Orden de Compra'] === 'number') nroDocumento = p['Orden de Compra']
        const spmkObject = {
          documentos: {
            nro_cliente: p['  Cliente'],
            nro_documento: nroDocumento,
            nro_transporte: p.Transporte,
            nro_vuelta: p.Vuelta,
            cantidad_uc: p['UC P'],
            detalle: p['Desc.'],
            fecha: fechaFormateada
          },
          nro_transporte: p.Transporte,
          vuelta: p.Vuelta,
          fecha_ruta: fechaFormateada,
          cd: 'CD Valdovinos',
          total_uc: p['UC P'],
          nro_camion: p['Cam Basis'],
          nro_cliente: p['  Cliente']
        }
        spmk.push(spmkObject)
      })
      console.log(spmk)
      const consolidados = {}
      spmk.forEach(objeto => {
        const llave = `${objeto.nro_transporte}-${objeto.nro_cliente}`
        if (!consolidados[llave]) {
          consolidados[llave] = {
            nro_transporte: objeto.nro_transporte,
            nro_cliente: objeto.nro_cliente,
            total_uc: objeto.total_uc,
            vuelta: objeto.vuelta,
            fecha_ruta: objeto.fecha_ruta,
            cd: objeto.cd,
            nro_camion: objeto.nro_camion,
            documentos: [objeto.documentos]
          }
        } else {
          consolidados[llave].total_uc += objeto.total_uc
          consolidados[llave].documentos.push(objeto.documentos)
        }
      })
      const resultado = Object.values(consolidados)
      console.log(resultado)
      this.setEntregaDiariaAsync(resultado).then((res) => {
        console.log('setEntregaDiariaAsync')
        const toast = {}
        toast.body = 'Se ha cargado el archivo con exito'
        toast.title = 'Carga Exitosa'
        toast.variant = 'success'
        this.makeToast(toast)
        // this.fileRouteView = []
        // this.$emit('updateClientes', this.clientes)
      })
    },
    onFileChange (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.createInput(files[0])
    },
    createInput (file) {
      var reader = new FileReader()
      var vm = this
      reader.onload = (e) => {
        vm.fileinput = reader.result
      }
      reader.readAsText(file)
      console.log(this.fileinput)
      this.routeView = this.fileinput
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const clienteFoco = [
        { nro_cliente: '', cliente_foco: '' }
      ]
      // let items = {}
      // for (const item in this.items) {
      //   items = {}
      //   for (const [key, value] of Object.entries(this.items[item])) {

      //     if (!key.includes('_cellVariants')) {
      //       items[key] = value
      //     }
      //   }
      //   itemsAux.push(items)
      // }
      const ws = XLSX.utils.json_to_sheet(clienteFoco)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'plantilla')
      XLSX.writeFile(wb, 'clientes_foco.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    enviarArchivoPlanificacion () {
      console.log('enviarArchivoPlanificacion')
      const InstFormData = new FormData()
      InstFormData.append('file', this.filePlanificacion)
      console.log(InstFormData)
      if (this.filePlanificacion != null) {
        console.log('no es null')
        this.setPlanificaciones(InstFormData).then((res) => {
          console.log(res)
          const toast = {
            body: 'Actualizacion registos planificacion',
            title: 'Carga Exitosa',
            variant: 'success'
          }
          this.makeToast(toast)
        })
      } else {
        const toast = {
          body: 'Error en archivo cargado',
          title: 'Error',
          variant: 'danger'
        }
        this.makeToast(toast)
      }
    }
  },
  computed: {
    ...mapState('Clientes', ['clientes']),
    ...mapState('EntregaDiaria', ['entregaDiaria']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
